import React from 'react'
import { Button, Box, Heading, Layer, Footer, Text } from 'grommet'
import { SidebarBox } from './SidebarBox'
import { useMazeGame } from '../contexts'
import { DataRow } from '../../../common/components/DataRow'
import { getRoundStats } from '../contexts/selectors'
import { formatMs } from '../utils'
import { Round } from '../types'

const ResultsColumn: React.FC<{ round: Round }> = ({ round }) => {
  const roundStats = getRoundStats(round)
  return (
    <SidebarBox width="300px">
      <Box justify="between" direction="row">
        <Heading level="4">ROUND {round.order}</Heading>
        {round.state === 'ABORTED' && (
          <Text margin={{ left: 'small' }} color="status-critical" size="xsmall">
            ABORTED
          </Text>
        )}
      </Box>
      <Box margin={{ bottom: 'small' }}>
        <DataRow label="POINTS:" value={round.points} />
        <DataRow label="DAMAGE:" value={round.repeatMistakeCount} />
        <DataRow label="TOTAL TIME:" value={formatMs(roundStats.totalTime, 'mm:ss:SS')} />
      </Box>
      <DataRow label="PLANNING TIME:" value={formatMs(roundStats.timeUntilFirstMove, 'mm:ss:SS')} />
      <DataRow
        label="AVG. MOVE TIME:"
        value={formatMs(roundStats.averageTimeBetweenMoves, 'mm:ss:SS')}
      />
      <DataRow label="RESTART COUNT:" value={roundStats.restartCount} />
    </SidebarBox>
  )
}

export const ShowResultsButton = () => {
  const Game = useMazeGame()
  const [open, setOpen] = React.useState(false)
  const onOpen = () => setOpen(true)
  const onClose = () => setOpen(false)

  return (
    <>
      <Button primary label={'SHOW RESULTS'} onClick={onOpen} />
      {open && (
        <Layer position="center" modal onClickOutside={onClose} onEsc={onClose} responsive={false}>
          <Box pad="medium" background="dark-1" gap="medium">
            <Heading level="3">RESULTS</Heading>
            <Box direction="row" gap="small">
              {Game.roundHistory.map((round) => (
                <ResultsColumn round={round}></ResultsColumn>
              ))}
            </Box>
            <Footer>
              <Button label="Close" onClick={onClose} primary color="status-critical" />
            </Footer>
          </Box>
        </Layer>
      )}
    </>
  )
}
