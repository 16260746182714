import React from 'react'
import { Box, Text } from 'grommet'
import { useMazeGame } from '../contexts'
import { Timer } from './Timer'
import { SidebarBox } from './SidebarBox'
import { formatMs } from '../utils'
import { DataRow } from '../../../common/components/DataRow'
import { getRoundStats } from '../contexts/selectors'
import { Round } from '../types'

const SidebarRoundInfo: React.FC<{ round: Round }> = ({ round }) => {
  const stats = getRoundStats(round)

  return (
    <SidebarBox>
      <Box justify="between" direction="row">
        <Text
          margin={{
            bottom: 'small',
          }}
        >
          {`ROUND ${round.order}`}
        </Text>
        {round.state === 'ABORTED' && (
          <Text margin={{ left: 'small' }} color="status-critical" size="xsmall">
            ABORTED
          </Text>
        )}
      </Box>
      <DataRow label="SCORE:" value={round.points} />
      <DataRow label="DAMAGE:" value={round.repeatMistakeCount} />
      <DataRow label="TOTAL TIME:" value={formatMs(stats.totalTime, 'mm:ss:SS')} />
    </SidebarBox>
  )
}

const SidebarRoundsInfo: React.FC = () => {
  const { currentRound, roundHistory } = useMazeGame()
  return (
    <Box gap="medium">
      {roundHistory.map((round) => (
        <SidebarRoundInfo round={round}></SidebarRoundInfo>
      ))}
      {['INITIAL', 'STARTED'].includes(currentRound.state) && (
        <SidebarRoundInfo round={currentRound}></SidebarRoundInfo>
      )}
    </Box>
  )
}

const RightColumn: React.FC = () => {
  const Game = useMazeGame()

  return (
    <Box pad="medium" gap="medium" width={'350px'}>
      <Timer
        start={Game.currentRound.state === 'STARTED'}
        stop={Game.currentRound.state === 'COMPLETED' || Game.currentRound.state === 'ABORTED'}
        reset={Game.currentRound.state === 'INITIAL'}
      />
      <SidebarRoundsInfo />
    </Box>
  )
}

export default RightColumn
