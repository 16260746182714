import React from 'react'
import { Button, Box, Heading, Layer, Text } from 'grommet'
import { useMazeGame } from '../contexts'

export const AbortRoundButton = () => {
  const Game = useMazeGame()
  const [open, setOpen] = React.useState(false)
  const onOpen = () => setOpen(true)
  const onClose = () => setOpen(false)
  const onConfirm = () => {
    Game.abortRound()
    setOpen(false)
  }
  return (
    <>
      <Button
        style={{ fontWeight: 'bold', color: 'white' }}
        label={'ABORT'}
        onClick={onOpen}
        disabled={Game.currentRound.state !== 'STARTED'}
      />
      {open && (
        <Layer position="center" modal onClickOutside={onClose} onEsc={onClose} responsive={false}>
          <Box pad="medium" gap="small" width="medium">
            <Heading level={3}>Confirm</Heading>
            <Text>Are you sure you want to abort this round?</Text>
            <Box
              as="footer"
              gap="small"
              direction="row"
              align="center"
              justify="end"
              pad={{ top: 'medium', bottom: 'small' }}
            >
              <Button label="CANCEL" onClick={onClose} />
              <Button label="YES" onClick={onConfirm} primary color="status-critical" />
            </Box>
          </Box>
        </Layer>
      )}
    </>
  )
}
