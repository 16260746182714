import React from 'react'
import { Box, BoxProps } from 'grommet'
export const SidebarBox: React.FC<BoxProps> = (props) => (
  <Box
    background={{ color: 'dark-1' }}
    pad="small"
    round="small"
    border={{ color: 'accent-1', size: '2px' }}
    {...props}
  ></Box>
)
