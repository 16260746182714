import * as fromActions from './actions'
import { createMove } from '../utils'
import { GameState, Round } from '../types'

export interface State {
  state: GameState
  roundHistory: Round[]
  currentRound: Round
}

export const initialState: State = {
  state: 'INITIAL',
  roundHistory: [],
  currentRound: {
    order: 1,
    state: 'INITIAL',
    moveListHistory: [],
    moveList: [],
    restartCount: 0,
    currentTile: { x: null, y: null },
    isTileWrong: false,
    points: 0,
    repeatMistakeCount: 0,
  },
}

export default function reducer(state = initialState, action: fromActions.Actions): State {
  switch (action.type) {
    case 'START_ROUND':
      return {
        ...state,
        currentRound: {
          ...state.currentRound,
          state: 'STARTED',
          startedAt: new Date(),
        },
      }
    case 'RESET_ROUND':
      return {
        ...state,
        currentRound: {
          ...initialState.currentRound,
          order: state.roundHistory.length + 1,
        },
      }
    case 'COMPLETE_ROUND':
      return {
        ...state,
        currentRound: {
          ...state.currentRound,
          state: 'COMPLETED',
          endedAt: new Date(),
        },
      }
    case 'ABORT_ROUND':
      return {
        ...state,
        currentRound: {
          ...state.currentRound,
          state: 'ABORTED',
          endedAt: new Date(),
        },
      }
    case 'SAVE_ROUND_TO_HISTORY':
      return {
        ...state,
        roundHistory: [...state.roundHistory, state.currentRound],
      }
    case 'RESTART_ROUND':
      return {
        ...state,
        currentRound: {
          ...state.currentRound,
          currentTile: initialState.currentRound.currentTile,
          isTileWrong: initialState.currentRound.isTileWrong,
          moveList: initialState.currentRound.moveList,
          restartCount: state.currentRound.restartCount + 1,
        },
      }

    // Round Points
    case 'ADD_POINTS':
      return {
        ...state,
        currentRound: {
          ...state.currentRound,
          points: state.currentRound.points + action.payload,
        },
      }
    case 'REMOVE_POINTS':
      return {
        ...state,
        currentRound: {
          ...state.currentRound,
          points: state.currentRound.points - action.payload,
        },
      }

    // Game
    case 'COMPLETE_GAME':
      return {
        ...state,
        state: 'COMPLETED',
      }
    case 'RESET_GAME':
      return initialState

    // Tiles
    case 'MOVE':
      const isFirstMove = state.currentRound.moveListHistory.length === 0

      return {
        ...state,
        currentRound: {
          ...state.currentRound,
          currentTile: action.payload,
          moveListHistory: [...state.currentRound.moveListHistory, createMove(action.payload)],
          moveList: [...state.currentRound.moveList, action.payload],
          firstMoveAt: isFirstMove ? new Date() : state.currentRound.firstMoveAt,
        },
      }
    case 'MOVED_TO_INCORRECT_TILE':
      return {
        ...state,
        currentRound: {
          ...state.currentRound,
          isTileWrong: true,
        },
      }
    case 'INCREMENT_REPEAT_MISTAKE_COUNT':
      return {
        ...state,
        currentRound: {
          ...state.currentRound,
          repeatMistakeCount: state.currentRound.repeatMistakeCount + 1,
        },
      }
    default:
      return state
  }
}
