import React from 'react'
import { Box } from 'grommet'
import PurpleTileWide from '../assets/purple-tile-wide.png'

interface TileProps {
  text: string
}

const Tile: React.FC<TileProps> = ({ text }) => {
  return (
    <Box>
      <Box
        pad="small"
        align="center"
        background={{
          image: `url(${PurpleTileWide})`,
        }}
      >
        {text}
      </Box>
    </Box>
  )
}

export default React.memo(Tile)
