import React from 'react'
import { Box, Grommet, Image } from 'grommet'
import Board from '../modules/maze/components/Board'
import RightColumn from '../modules/maze/components/RightColumn'
import LeftColumn from '../modules/maze/components/LeftColumn'
import { MazeGameEngineProvider } from '../modules/maze/contexts'
import theme from '../modules/maze/theme'
import SpaceBackgroundImg from '../modules/maze/assets/space-background.png'
import EarthImg from '../modules/maze/assets/earth.png'

const MazeGamePage: React.FC = () => {
  return (
    <Grommet theme={theme} full>
      <MazeGameEngineProvider>
        <Box
          fill
          background={{
            image: `url(${SpaceBackgroundImg})`,
          }}
        >
          <Box direction="row" justify="center" style={{ zIndex: 1 }}>
            <LeftColumn />
            <Board />
            <RightColumn />
          </Box>
          <Image
            src={EarthImg}
            width="25%"
            style={{ position: 'absolute', bottom: 0, right: 0, zIndex: 0 }}
          />
        </Box>
      </MazeGameEngineProvider>
    </Grommet>
  )
}

export default MazeGamePage
