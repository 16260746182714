import { ThemeType } from 'grommet'

const theme: ThemeType = {
  global: {
    font: {
      family: 'Roboto',
    },
  },
}

export default theme
