import { ActionsUnion, createAction } from '@martin_hotell/rex-tils'
import { Coordinate } from '../types'

export const MOVE = 'MOVE'
export const MOVED_TO_INCORRECT_TILE = 'MOVED_TO_INCORRECT_TILE'
export const INCREMENT_REPEAT_MISTAKE_COUNT = 'INCREMENT_REPEAT_MISTAKE_COUNT'

export const ABORT_ROUND = 'ABORT_ROUND'
export const COMPLETE_ROUND = 'COMPLETE_ROUND'
export const SAVE_ROUND_TO_HISTORY = 'SAVE_ROUND_TO_HISTORY'
export const START_ROUND = 'START_ROUND'
export const RESET_ROUND = 'RESET_ROUND'
export const RESTART_ROUND = 'RESTART_ROUND'
export const ADD_POINTS = 'ADD_POINTS'
export const REMOVE_POINTS = 'REMOVE_POINTS'

export const COMPLETE_GAME = 'COMPLETE_GAME'
export const RESET_GAME = 'RESET_GAME'

export const Actions = {
  move: (nextTile: Coordinate) => createAction(MOVE, nextTile),
  movedToIncorrectTile: () => createAction(MOVED_TO_INCORRECT_TILE),
  incrementRepeatMistakeCount: () => createAction(INCREMENT_REPEAT_MISTAKE_COUNT),

  startRound: () => createAction(START_ROUND),
  resetRound: () => createAction(RESET_ROUND),
  restartRound: () => createAction(RESTART_ROUND),
  abortRound: () => createAction(ABORT_ROUND),
  completeRound: () => createAction(COMPLETE_ROUND),
  saveRoundToHistory: () => createAction(SAVE_ROUND_TO_HISTORY),
  addPoints: (points: number) => createAction(ADD_POINTS, points),
  removePoints: (points: number) => createAction(REMOVE_POINTS, points),

  completeGame: () => createAction(COMPLETE_GAME),
  resetGame: () => createAction(RESET_GAME),
}

export type Actions = ActionsUnion<typeof Actions>
