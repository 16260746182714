import React from 'react'
import { Grommet, Main } from 'grommet'
import { BrowserRouter as Router } from 'react-router-dom'
import { AppRouter } from './AppRouter'
import theme from './theme'

export const AppProviders: React.FC = ({ children }) => (
  <Router>
    <Grommet theme={theme} full>
      {children}
    </Grommet>
  </Router>
)

const App = () => {
  return (
    <AppProviders>
      <Main background={{ color: 'dark-1' }}>
        <AppRouter />
      </Main>
    </AppProviders>
  )
}

export default App
