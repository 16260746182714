import { differenceInMilliseconds } from 'date-fns'
import { Round } from '../types'

export type RoundStats = ReturnType<typeof getRoundStats>

export function getRoundStats(round: Round) {
  let timeUntilFirstMove = 0
  let totalTime = 0
  let repeatMistakeCount = 0

  const { startedAt, firstMoveAt, endedAt: completedAt, restartCount, moveListHistory } = round

  if (startedAt && firstMoveAt) {
    timeUntilFirstMove = differenceInMilliseconds(firstMoveAt, startedAt)
  }
  if (startedAt && completedAt) {
    totalTime = differenceInMilliseconds(completedAt, startedAt)
  }

  const averageTimeBetweenMoves = (totalTime - timeUntilFirstMove) / (moveListHistory.length - 1)

  return {
    totalTime,
    timeUntilFirstMove,
    averageTimeBetweenMoves,
    restartCount: restartCount,
    repeatMistakeCount,
  }
}
