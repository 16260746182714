import React from 'react'
import { Button, Box } from 'grommet'
import { useMazeGame } from '../contexts'
import { AbortRoundButton } from './AbortRoundButton'

export const GameControls: React.FC = () => {
  const Game = useMazeGame()
  return (
    <Box>
      {Game.state === 'COMPLETED' ? (
        <Box direction="row" gap="small">
          <Button
            style={{ fontWeight: 'bold' }}
            primary
            label="RESET GAME"
            onClick={() => Game.resetGame()}
          />
        </Box>
      ) : ['COMPLETED', 'ABORTED'].includes(Game.currentRound.state) ? (
        <Button
          style={{ fontWeight: 'bold' }}
          primary
          label={'NEXT ROUND'}
          onClick={() => Game.resetRound()}
        />
      ) : (
        <Box direction="row-responsive" gap="small">
          <Button
            style={{ fontWeight: 'bold' }}
            primary
            label={`START`}
            onClick={() => Game.startRound()}
            disabled={Game.currentRound.state === 'STARTED'}
          />
          <AbortRoundButton />
        </Box>
      )}
    </Box>
  )
}
