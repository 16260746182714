import UIfx from 'uifx'

const incorrectMove = new UIfx(`${process.env.PUBLIC_URL}/audio/explosion.mp3`, { volume: 0.6 })
const correctMove = new UIfx(`${process.env.PUBLIC_URL}/audio/ring-ding.mp3`, { volume: 0.6 })
const roundComplete = new UIfx(`${process.env.PUBLIC_URL}/audio/round-complete.mp3`, {
  volume: 0.6,
})

export default {
  correctMove,
  incorrectMove,
  roundComplete,
}
