import React from 'react'
import { Heading, Paragraph } from 'grommet'
import { useMazeGame } from '../contexts'
import { SidebarBox } from './SidebarBox'
import { ShowResultsButton } from './ShowResultsButton'

const SidebarMessage: React.FC = ({ children }) => {
  return (
    <SidebarBox background="light-1" animation={{ type: 'zoomIn', size: 'xlarge', duration: 500 }}>
      {children}
    </SidebarBox>
  )
}

export const SidebarNotifier: React.FC = () => {
  const Game = useMazeGame()
  return Game.currentRound.isTileWrong ? (
    <SidebarMessage>
      <Heading level="4" margin="none">
        OH NO!
      </Heading>
      <Paragraph size="small">You were hit by an asteroid.</Paragraph>
    </SidebarMessage>
  ) : Game.state === 'COMPLETED' || Game.currentRound.state === 'COMPLETED' ? (
    <SidebarMessage>
      <Heading level="4" margin="none">
        FINISHED!
        <Paragraph size="small">See how well you did</Paragraph>
        <ShowResultsButton />
      </Heading>
    </SidebarMessage>
  ) : null
}
