import React, { useEffect } from 'react'
import { Heading, Paragraph } from 'grommet'
import { SidebarBox } from './SidebarBox'
import { useTimer } from 'react-compound-timer'

export const TimerText: React.FC = ({ children }) => (
  <Paragraph
    size="large"
    margin={{ bottom: '0' }}
    style={{
      whiteSpace: 'nowrap',
    }}
  >
    {children}
  </Paragraph>
)

export const Timer: React.FC<{
  start?: boolean
  stop?: boolean
  reset?: boolean
}> = ({ start, stop, reset }) => {
  const { value, controls } = useTimer({
    startImmediately: false,
    formatValue: (value) => `${value < 10 ? `0${value}` : value}`,
    timeToUpdate: 10,
  })

  const formatMs = (value: number) => {
    if (value < 10) return `00${value}`
    if (value < 100) return `0${value}`
    return `${value}`
  }

  useEffect(() => {
    start && controls.start()
  }, [controls, start])
  useEffect(() => {
    stop && controls.stop()
  }, [controls, stop])
  useEffect(() => {
    reset && controls.reset()
  }, [controls, reset])

  return (
    <SidebarBox>
      <Heading level="5" margin="none">
        TIMER
      </Heading>
      {value.state === 'INITED' ? (
        <TimerText>--:--:--</TimerText>
      ) : (
        <TimerText>{`${value.m}m:${value.s}s:${formatMs(value.ms)}`}</TimerText>
      )}
    </SidebarBox>
  )
}
