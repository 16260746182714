import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import MazeGamePage from './pages/MazeGamePage'

export const AppRouter: React.FC = () => (
  <Switch>
    <Route exact path="/" component={MazeGamePage} />
    <Route component={() => <Redirect to="/" />} />
  </Switch>
)
