import { Duration } from 'luxon'
import { Coordinate, Move } from './types'

export function isSameCoordinate(a?: Coordinate, b?: Coordinate) {
  if (!a || !b) {
    return false
  }
  return a.x === b.x && a.y === b.y
}

export function formatMs(milliseconds: number, format: string) {
  if (milliseconds === 0) {
    return '--:--:--'
  }
  const duration = Duration.fromObject({ milliseconds })

  return duration.toFormat(format)
}

export const createMove = (to: Coordinate): Move => {
  return { at: new Date(), to }
}
