import React from 'react'
import { Box } from 'grommet'
import Tile from './Tile'
import { useMazeGame } from '../contexts'
import { isSameCoordinate } from '../utils'
import { default as matrixModule } from '../matrix'
import TileWide from './TileWide'
import { Coordinate } from '../types'

const Board: React.FC = () => {
  const Game = useMazeGame()
  const matrix = matrixModule.get(Game.currentRound.order)

  return (
    <Box pad="medium" align="center" justify="start" direction="column" gap="small">
      <Box gap="small">
        <TileWide text="ENTRANCE" />
        {matrix.map((row, rowIdx) => (
          <Box key={`row-${rowIdx}`} direction="row" gap="small">
            {row.map((cell, colIdx) => {
              const thisTile: Coordinate = { x: colIdx, y: rowIdx }

              return (
                <Tile
                  key={`${rowIdx}${colIdx}`}
                  value={cell}
                  type={Game.getTileState(thisTile, cell)}
                  onClick={() => Game.move(thisTile, cell)}
                  isCurrent={isSameCoordinate(thisTile, Game.currentRound.currentTile)}
                  disabled={
                    Game.currentRound.state === 'INITIAL' ||
                    Game.currentRound.state === 'COMPLETED' ||
                    Game.currentRound.state === 'ABORTED' ||
                    Game.currentRound.isTileWrong ||
                    !Game.isValidNextMove(thisTile)
                  }
                />
              )
            })}
          </Box>
        ))}
        <TileWide text="EXIT" />
      </Box>
    </Box>
  )
}

export default Board
