import React from 'react'
import { SidebarBox } from './SidebarBox'
import { Box, Paragraph, Heading } from 'grommet'
import { SidebarNotifier } from './SidebarNotifier'
import { GameControls } from './GameControls'

const LeftColumn: React.FC = () => {
  return (
    <Box pad="medium" gap="medium" width={'350px'}>
      <SidebarBox>
        <Heading level="5" margin={{ top: 'none', bottom: 'small' }}>
          SCORING
        </Heading>
        <Paragraph size="small">-100 points for every minute that goes by.</Paragraph>
        <Paragraph size="small">-100 points for hitting the same asteroid again!</Paragraph>
        <Paragraph size="small">+10000 points for making it through the asteroid field!</Paragraph>
      </SidebarBox>
      <GameControls />
      <SidebarNotifier />
    </Box>
  )
}

export default LeftColumn
