import { isSameCoordinate } from '../../modules/maze/utils'
import { Coordinate } from './types'

type Matrix = number[][]

// FIXME: The final move needs to have a value of 10,
// as this is checked in the game service
const variations: { [roundNumber: number]: Matrix } = {
  1: [
    [1, 0, 1, 1, 1],
    [2, 0, 0, 2, 2],
    [0, 3, 0, 0, 0],
    [0, 0, 4, 0, 0],
    [0, 0, 0, 5, 0],
    [0, 0, 10, 0, 0],
  ],
  2: [
    [0, 0, 0, 1, 0],
    [0, 0, 2, 0, 0],
    [0, 3, 0, 0, 0],
    [4, 0, 0, 7, 0],
    [0, 5, 6, 0, 8],
    [0, 0, 0, 0, 10],
  ],
  3: [
    [0, 0, 0, 1, 0],
    [0, 5, 0, 0, 2],
    [6, 0, 4, 3, 0],
    [7, 0, 0, 0, 0],
    [0, 8, 9, 0, 0],
    [0, 0, 0, 10, 0],
  ],
}

const get = (roundNumber: number): Matrix => {
  const matrix = variations[roundNumber]
  return matrix || []
}

const build = (columns: number, rows: number) => {
  return Array<number>(rows)
    .fill(0)
    .map((row) => {
      return Array<number>(columns).fill(0)
    })
}

const adjacentElements = (target: Coordinate) => {
  const result: Coordinate[] = []

  if (target.x === null || target.y === null) return result
  for (let x: number = target.x - 1; x <= target.x + 1; x++) {
    for (let y: number = target.y - 1; y <= target.y + 1; y++) {
      if (x >= 0 && y >= 0) {
        if (!(target.x === x && target.y === y)) {
          result.push({ x, y })
        }
      }
    }
  }
  return result
}

const isAdjacent = (current: Coordinate, target: Coordinate) => {
  return adjacentElements(current).filter((sibling) => isSameCoordinate(sibling, target)).length > 0
}

export default {
  get,
  build,
  adjacentElements,
  isAdjacent,
}
