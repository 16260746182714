import React, { createContext, useContext, useReducer, useEffect } from 'react'
import { isSameCoordinate } from '../utils'
import sounds from '../sounds'
import matrix from '../matrix'

import reducer, { initialState, State } from './reducer'
import * as fromActions from './actions'
import { Coordinate, TileState } from '../types'

interface ContextValue extends State {
  resetRound(): void
  startRound(): void
  abortRound(): void
  move(nextTile: Coordinate, tileValue: number): void
  getTileState(tile: Coordinate, tileValue: number): TileState
  isValidNextMove(tile: Coordinate): boolean
  resetGame(): void
}

const Context = createContext({} as ContextValue)

export const MazeGameEngineProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const { currentRound } = state

  useEffect(() => {
    let timer = 0
    const oneMinute = 1000 * 60
    if (state.currentRound.state === 'STARTED') {
      clearInterval(timer)
      timer = window.setInterval(() => dispatch(fromActions.Actions.removePoints(100)), oneMinute)
    }
    if (state.currentRound.state === 'COMPLETED') {
      clearInterval(timer)
    }
    return () => clearInterval(timer)
  }, [state.currentRound.state])

  useEffect(() => {
    // end the game after 3 rounds
    if (state.roundHistory.length === 3) {
      dispatch(fromActions.Actions.completeGame())
    }
  }, [state.roundHistory])

  const actions = {
    move: (nextTile: Coordinate, tileValue: number) => {
      dispatch(fromActions.Actions.move(nextTile))
      if (tileValue === 0) {
        sounds.incorrectMove.play()
        dispatch(fromActions.Actions.movedToIncorrectTile())
        setTimeout(() => dispatch(fromActions.Actions.restartRound()), 2000)
        if (isSameMistakeAsAPreviousMove(state, nextTile)) {
          dispatch(fromActions.Actions.incrementRepeatMistakeCount())
          dispatch(fromActions.Actions.removePoints(100))
        }
      } else if (tileValue === 10) {
        sounds.roundComplete.play()
        dispatch(fromActions.Actions.completeRound())
        dispatch(fromActions.Actions.addPoints(10000))
        dispatch(fromActions.Actions.saveRoundToHistory())
      } else {
        sounds.correctMove.play()
      }
    },
    startRound: () => {
      dispatch(fromActions.Actions.startRound())
    },
    resetRound: () => dispatch(fromActions.Actions.resetRound()),
    abortRound: () => {
      dispatch(fromActions.Actions.abortRound())
      dispatch(fromActions.Actions.saveRoundToHistory())
    },
    getTileState: (coordinate: Coordinate, tileValue: number): TileState => {
      // show tile is if was moved to in the round
      if (
        state.currentRound.state === 'COMPLETED' &&
        state.currentRound.moveListHistory.find((move) => {
          return isSameCoordinate(move.to, coordinate)
        })
      ) {
        return tileValue === 0 ? 'INCORRECT' : 'CORRECT'
      }
      // dont show tile if not the current one
      if (!isSameCoordinate(state.currentRound.currentTile, coordinate)) {
        return 'UNTOUCHED'
      }
      // show tile
      return tileValue === 0 ? 'INCORRECT' : 'CORRECT'
    },
    isValidNextMove: (location: Coordinate) => {
      const isFirstMoveAndFirstRow = () => {
        return currentRound.moveList.length === 0 && location.y === 0
      }
      if (isFirstMoveAndFirstRow()) return true
      return matrix.isAdjacent(currentRound.currentTile, location)
    },
    resetGame: () => dispatch(fromActions.Actions.resetGame()),
  }

  return (
    <Context.Provider
      value={{
        ...state,
        ...actions,
      }}
    >
      {children}
    </Context.Provider>
  )
}

export const useMazeGame = () => useContext(Context)
function isSameMistakeAsAPreviousMove(state: State, nextTile: Coordinate) {
  return state.currentRound.moveListHistory.find((previousMove) => {
    return isSameCoordinate(previousMove.to, nextTile)
  })
}
