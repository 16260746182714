import { ThemeType } from 'grommet'

const theme: ThemeType = {
  global: {
    font: {
      family: 'PressStart2P',
    },
    breakpoints: {
      small: { value: 1000 },
    },
    colors: {
      'accent-1': '#00a9f4',
      'dark-1': '#000',
    },
  },
  button: {
    border: {
      radius: '8px',
    },
  },
}

export default theme
