import React from 'react'
import { Box, Image, BoxProps } from 'grommet'
import PurpleTile from '../assets/purple-tile.png'
import PurpleTileBordered from '../assets/purple-tile-bordered.png'
import BlueTile from '../assets/blue-tile.png'
import AsteroidTile from '../assets/asteroid.png'
import { TileState } from '../types'

interface TileProps {
  value: number
  type: TileState
  disabled?: boolean
  isCurrent?: boolean
  onClick?: () => void
}
const Tile: React.FC<TileProps> = ({ type, onClick, disabled }) => {
  let tile
  const length = '9vh'
  const BaseTile: React.FC<BoxProps> = (props) => (
    <Box width={length} height={length} onClick={disabled ? undefined : onClick} {...props} />
  )
  switch (type) {
    case 'CORRECT':
      tile = (
        <BaseTile
          animation={disabled ? undefined : { type: 'pulse', duration: 500, size: 'medium' }}
        >
          <Image fit="contain" src={BlueTile} />
        </BaseTile>
      )
      break

    case 'INCORRECT':
      tile = (
        <BaseTile animation={{ type: 'zoomIn', duration: 300, size: 'xlarge' }}>
          <Image fit="contain" src={AsteroidTile} />
        </BaseTile>
      )
      break

    default:
      tile = disabled ? (
        <BaseTile>
          <Image fit="contain" src={PurpleTile} />
        </BaseTile>
      ) : (
        <BaseTile animation={{ type: 'pulse', duration: 500, size: 'medium' }}>
          <Image fit="contain" src={PurpleTileBordered} />
        </BaseTile>
      )
      break
  }
  return tile
}

export default React.memo(Tile)
