import React from 'react'
import { Box, Text } from 'grommet'

export const DataRow: React.FC<{
  label: string
  value: string | number
}> = ({ label, value }) => (
  <Box direction="row" justify="between">
    <Text size="xsmall">{label}</Text>
    <Text size="xsmall">{value}</Text>
  </Box>
)
